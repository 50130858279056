<template>
  <div class="user-index">
    <van-nav-bar
      title="设置"
      placeholder
      :style="{'padding-top': statusBarHeight + 'px'}"
      safe-area-inset-top
    />
    <van-cell-group :style="{height: `calc(100vh - 96px - ${moreHeight}px)`}">
      <div class="user-index-box">
        <van-cell title="基本设置" is-link icon="user-o" :to="{ name: 'BasicSetting'}" />
      </div>
      <div class="user-index-box">
        <van-cell title="安全设置" is-link icon="diamond-o" :to="{ name: 'SecuritySetting'}"/>
      </div>
      <div class="user-index-box">
        <van-cell title="配置服务器地址" is-link icon="setting-o" @click="configServerUrl"/>
      </div>
      <div class="user-index-box">
        <van-cell icon="stop-circle-o" title="退出登录" is-link @click="onLogout"/>
      </div>
    </van-cell-group>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'UserIndex',
  components: {},
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({
      userProfile: state => state.user.profile,
      statusBarHeight: state => state.global.statusBarHeight,
      moreHeight: state => state.global.moreHeight,
      deviceType: state => state.global.deviceType
    }),
    avatarUrl () {
      if (this.userProfile && this.userProfile.avatar) {
        return `https://xbi.hydts.cn/api${this.userProfile.avatar}`
      } else {
        return ''
      }
    }
  },
  methods: {
    getUserProfile () {
      this.$api.user.getUserProfile().then(res => {
        this.$store.commit('user/setProfile', res)
        console.log(res)
      }).catch((e) => {})
    },
    configServerUrl() {
      if (this.deviceType === 'ios') {
        // eslint-disable-next-line no-undef
        uni.webView.navigateTo({ url: 'set' })
      } else {
        window['XbiApp']['configServerUrl']()
      }
    },
    onLogout () {
      const toast = this.$toast.loading({
        message: '正在退出...',
        forbidClick: true
      })
      this.$api.user.logout().then(res => {
        this.$ls.remove('accessToken')
        this.$store.dispatch('user/logout')
        toast.clear()
        this.$router.replace('/login')
      }).catch(() => {
      })
    }
  },
  mounted () {
    console.log('this.$route.params', this.$route.params)
    this.getUserProfile()
  }
}
</script>
<style lang="less">
  .user-index {
    background-color: #f8f8f8;
    height: 100vh;
    background-image: url("~@/assets/background.png");
    background-size: 100% 40%;
    background-repeat: no-repeat;
    .van-nav-bar {
      background-color: transparent;
      height: 40px !important;
      .van-nav-bar__content {
        height: 40px;
        .van-nav-bar__title {
          font-size: 19px;
          font-weight: bold;
          color: #ffffff;
          font-family: PingFang SC;
        }
      }
      &::after {
        border-bottom-width: 0
      }
    }
    .van-cell-group {
      background-color: transparent;
      overflow: auto;
      padding-top: 6px;
      .user-index-box {
        margin: 0 15px 15px 15px;
        background-color: #ffffff;
        border-radius: 5px;
        .van-cell {
          border-radius: 5px;
          .van-cell__title {
            font-weight: bold;
            font-size: 16px;
          }
          .dashboard-cell-icon {
            .van-icon {
              width: 20px;
              height: 20px;
              background-color: #37acdf14;
              border-radius: 50%;
              text-align: center;

              &::before {
                color: #319DE2;
                vertical-align: middle;
              }
            }
          }
        }
      }
      &::after {
        border-top-width: 0
      }
    }
  }
</style>
